import React, { useState, useEffect } from "react";
// Components
import Banner from "./Banner";
import Introduction from "./Introduction";
import Benefits from "../Services/Zero";
import Types from "./Types";
import Layout from "Layout";
import Loading from "components/Loading";
// Images
import flexitank from "static/images/flexitank/desktop-flexitank.png";
import mobileFlexitank from "static/images/flexitank/mobile-flexitank.png";
import {
  FLEXITANK_META_DESCRIPTION,
  FLEXITANK_META_TITLE,
} from "constants/content.constant";
export default function Flexitank() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1300);
  }, []);
  return (
    <Layout
      metaTitle={FLEXITANK_META_TITLE}
      metaDescription={FLEXITANK_META_DESCRIPTION}
    >
      <div id="flexitank">
        {loading && <Loading />}
        <Banner />
        <Benefits />
        <Introduction />
        <img
          className="desktop-flexitank"
          src={flexitank}
          alt="Parts of Flexitank"
        />
        <img
          className="mobile-flexitank"
          src={mobileFlexitank}
          alt="Sai Flexitank"
        />
        <Types />
      </div>
    </Layout>
  );
}
