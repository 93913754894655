import React from "react";
import { Row, Col } from "antd";
import FadeIn from "react-fade-in";
import bannerAsset from "static/images/banner-asset-black.svg";
import { services } from "lists";
export default function Banner() {
  return (
    <div className="banner">
      <div className="top-banner">
        <div className="banner-content">
          <Row gutter={12}>
            <Col xs={24} lg={12} xl={10} className="left-col">
              <FadeIn delay="500">
                <h1 className="main-heading">Our Services</h1>
              </FadeIn>
              <FadeIn delay="500">
                <h2 className="main-subheading">
                  There are 6 Service Elements that forms a Smooth Flow for your
                  Bulk Liquid Logistics in Sai Flexitanks.
                  <br />
                  We are able to help you in your planning and execution with no
                  room for errors or mistakes!
                </h2>
              </FadeIn>
            </Col>
            <Col xs={24} lg={12} xl={14}>
              <FadeIn delay="500">
                <Row>
                  {services.map((service) => (
                    <Col key={service.id} xs={12} md={8}>
                      {/* <img className="service-image" src={image} alt="Product" /> */}
                      <a href={service.link}>
                      <div
                        className="service-image"
                        style={{
                          backgroundImage: `url(${service.product_image})`,
                        }}
                      />
                      <div className="service-title">
                        <h3>{service.title}</h3>
                      </div>
                      </a>
                    </Col>
                  ))}
                </Row>
              </FadeIn>
            </Col>
          </Row>
        </div>
        <img className="banner-asset" src={bannerAsset} alt="Banner Wave" />
      </div>
    </div>
  );
}
