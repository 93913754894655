import React from "react";
import { Row, Col } from "antd";
import FadeIn from "react-fade-in";
// Images
import bannerAsset from "static/images/banner-asset.svg";

export default function Banner() {
  return (
    <div className="banner">
      <div className="top-banner">
        <div className="banner-content">
          <Row gutter={12}>
            <Col xs={24} className="left-col">
              <FadeIn delay="500">
                <h1 className="main-heading">Products</h1>
              </FadeIn>
              <FadeIn delay="500">
                <h2 className="main-subheading">
                Explore our Wide Range of Flexitanks for Different Applications
                </h2>
              </FadeIn>
            </Col>
          </Row>
        </div>
        <img className="banner-asset" src={bannerAsset} alt="Banner Wave" />
      </div>
    </div>
  );
}
