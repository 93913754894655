import React, { useState, useEffect } from "react";
import { products } from "lists";

// Components
import Layout from "Layout";
import Banner from "./Banner";
import Watertank from "./Watertank";
import Product from "./Product";
import Loading from "components/Loading";
import {
  PRODUCTS_META_DESCRIPTION,
  PRODUCTS_META_TITLE,
} from "constants/content.constant";

export default function Products() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1300);
  }, []);
  return (
    <Layout
      metaTitle={PRODUCTS_META_TITLE}
      metaDescription={PRODUCTS_META_DESCRIPTION}
    >
      {loading && <Loading />}
      <div id="products">
        <Banner />
        <Watertank />
        {products.map((product) => (
          <Product key={product.id} {...product} />
        ))}
        {/* <Zero /> */}
      </div>
    </Layout>
  );
}
