
export const DEFAULT_META_TITLE = 'Saitank: Flexitank Supplier in India since 25 years';
export const DEFAULT_META_DESCRIPTION = 'Reliable and Strong Flexitanks Manufacturer in India. We are logistics specialists with over 25 years of experience providing services in India and worldwide';

export const SERVICES_META_TITLE = 'Non Hazardous Liquids In Flexitanks India. Export Import Worldwide';
export const SERVICES_META_DESCRIPTION = 'We are able to create & set up a Sai Flexitank Storage Facility at your Factory. We are committed to change the way Bulk Liquids are handled and stored and our entire focus is concentrated on Cost Savings and Reliability!';

export const FLEXITANK_META_TITLE = 'Flexitank for Water Storage and Distribution in India';
export const FLEXITANK_META_DESCRIPTION = 'Our Flexitanks are tamper proof which directly results in maintaining Quality of your Oil and keeps the Quantity intact. Get Flexi Tanks at the Best Price in India.';

export const PRODUCTS_META_TITLE = 'Rain Harvesting in Flexitanks in India';
export const PRODUCTS_META_DESCRIPTION = 'Explore our Wide Range of Flexitanks for Different Applications. We are an Experienced Flexitank Operator in India.';

export const CONTACT_META_TITLE = 'Door to Door Flexitank Operations in India';
export const CONTACT_META_DESCRIPTION = 'Leave it to us to find you perfect solutions for your Bulk Liquid Storage, Transport and Shipping. Contact us for Flexitank Shipping Worldwide too.';

