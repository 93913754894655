import React from "react";

// Components
import Navbar from "./Navbar";
import Footer from "./Footer";
import "static/styles/main.scss";
import { Helmet } from "react-helmet";
import {
  DEFAULT_META_DESCRIPTION,
  DEFAULT_META_TITLE,
} from "constants/content.constant";

export default function Layout({
  metaTitle = DEFAULT_META_TITLE,
  metaDescription = DEFAULT_META_DESCRIPTION,
  children,
}) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} key="ogtitle" />
          <meta property="og:description" content={metaDescription} key="ogdesc" />
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </React.Fragment>
  );
}
