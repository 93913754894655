import React from "react";
// Images
import importImage from "static/images/services/import.jpg";
import exportImage from "static/images/services/export.jpg";
import domestic from "static/images/services/domestic.jpg";
import storage from "static/images/services/storage.jpg";
import distribution from "static/images/services/distribution.jpg";
import expertise from "static/images/services/expertise.jpg";

export const services = [
  {
    id: 1,
    title: "Import",
    product_image: importImage,
    link: "#import",
  },
  {
    id: 2,
    title: "Export",
    product_image: exportImage,
    link: "#export",
  },
  {
    id: 3,
    title: "Distribution",
    product_image: distribution,
    link: "#distribution",
  },
  {
    id: 4,
    title: "Storage",
    product_image: storage,
    link: "#storage",
  },
  {
    id: 5,
    title: "Domestic",
    product_image: domestic,
    link: "#domestic",
  },
  {
    id: 6,
    title: "Expertise",
    product_image: expertise,
    link: "#expertise",
  },
];

export const serviceContent = [
  {
    id: 1,
    hash: "import",
    heading: "Import",
    type: "hard",
    side: "left-col",
    points: [
      <span>
        Global Pick Up Services from your Suppliers Factory with Delivery Right
        upto your Door
      </span>,
      <span>
        Monitoring Operations for Flexitank Fitting, Transportation and Shipping
      </span>,

      <span>
        Close Monitoring for Quality Control and Efficient Loading / Discharging
        Operations
      </span>,
      <span>Competitive Costs</span>,
      <span>Customs Clearance at both ends</span>,
      <span style={{ fontWeight: "800" }}> FREE : Consultancy</span>,
    ],
  },
  {
    id: 2,
    hash: "export",
    heading: "Export",
    type: "light",
    side: "right-col",

    points: [
      <span>
        Delivering from your Factory right upto your buyers Door, anywhere in
        the World
      </span>,
      <span>
        Ensuring smooth loading and discharging operations by our strong
        Overseas Partners
      </span>,
      <span>
        Selecting the Right type of Flexitank to match the MSDS of your Liquid
        Cargo
      </span>,
      <span>
        Choosing the most economical Shipping Line with the fastest Transit Time
      </span>,
      <span>
        Supervising Operations and Documentation from “ Source to End “
      </span>,
      <span style={{ fontWeight: "800" }}> FREE : Technical Assistance</span>,
    ],
  },
  {
    id: 3,
    hash: "storage",
    heading: "Storage",
    type: "hard",
    side: "left-col",
    description: (
      <p className="service-description">
        <span className="bold">
          Our Sai Flexitank Farm is located at Kalamboli, Near Nhava Sheva with
          expansion Plans to cover ALL INDIA Locations
        </span>
        <br />
        <span className="bold">
          We offer the following Storage Solutions for your complete benefit!
        </span>
      </p>
    ),
    points: [
      <span>
        Releasing Valuable Space occupied by empty and loaded Drums in your
        Factory Setting up of a Flexitank Storage Facility within your Premises
        or a:
      </span>,
      <span>Short Term and Long Term Storage at our Sai Flexi Tank Farm</span>,
      <span>
        Assured Savings with Special Rates with Monthly or Yearly Contracts
      </span>,
      // <span>
      //   Complete Inventory Control &amp; Fulfillment : From Collection to
      //   Storage to Distribution to Delivery
      // </span>,
      // <span>
      //   From Bulk Flexitank Packing to Retail Packing in IBC’s &#40; Interim
      //   Buck Carriers &#41; including re-labelling
      // </span>,
      <span>Safe Operations in our 24/7 Secured Flexitank Depot</span>,
      <span style={{ fontWeight: "800" }}> FREE : Estimation</span>,
    ],
  },
  {
    id: 4,
    hash: "distribution",
    heading: "Distribution",
    type: "light",
    side: "right-col",
    description: (
      <p className="service-description">
        <span className="bold">
          We are able to create &amp; set up a Sai Flexitank Storage Facility at
          your Factory. We are committed to change the way Bulk Liquids are
          handled and stored and our entire focus is concentrated on Cost
          Savings and Reliability!
        </span>
      </p>
    ),
    points: [
      <span>
        Complete Inventory Control &amp; Fulfillment : From Collection to
        Storage to Distribution to Delivery
      </span>,
      <span>
        From Bulk Flexitank Packing to Retail Packing in IBC’s ( Interim Buck
        Carriers ) including re-labelling
      </span>,
      <span>Planning of quick economical routes for timely deliveries</span>,
      <span>
        Setting up of a Flexitank Storage Facility within your Factory premises
      </span>,
      <span>Holistic &amp; Smooth Supply Chain Management Systems</span>,
      <span>Cost Saving Devices with Optimum Control</span>,
    ],
  },
  {
    id: 5,
    hash: "expertise",
    heading: "Expertise",
    type: "hard",
    side: "left-col",
    description: (
      <p className="service-description">
        <span>
          As Pioneers of the Flexitank in India, we have acquired volumes of
          knowledge that makes our Team Confident of giving you the Best
          Technical Guidance for any Bulk Liquid problem. Our expertise to Fit the Tank,
          Load and Discharge is based on years of Experience. The Support we get
          from our Renowned Flexitank Manufacturers adds flavour for us to work
          at the highest level of performance and precision.
        </span>
        <span>
          We also provide <b>EMERGENCY SERVICES</b> including Salvage Operations
          by involving our Field Staff who are capable of solving any untoward
          incident that takes place. Our Supervision &amp; Consultancy Services
          ensures that you and your valuable Cargo is in Safe Hands.
        </span>
      </p>
    ),
    points: [],
  },
  {
    id: 6,
    hash: "domestic",
    heading: "Domestic",
    type: "light",
    side: "right-col",
    points: [
      <span>
        Offering Tailor made Bulk Liquid Solutions across the entire Country
      </span>,
      <span>
        Linking  ALL States of India by Road and by Rail with Flexitanks in 20 Foot Containers
      </span>,
      <span>Using Top of the Line Transport Companies</span>,
      <span>
        Away from all the dangers of Contamination, Pilferage and Wastage
      </span>,
      <span>No Compromise on Quality, Quantity and time bound Operations</span>,
      <span>Sub Agency Network at all major Indian Towns &amp; Cities</span>,
    ],
  },
];
