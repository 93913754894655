import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Drawer, Menu, message } from "antd";
import arrow from "static/images/icons/white-right-arrow.svg";
import logo from "static/images/black-logo.png";
import menu from "static/images/icons/orange-menu.svg";
import phone from "static/images/phone.svg";
import { Helmet } from "react-helmet";

import Loading from "components/Loading";

import { NavLink } from "react-router-dom";
import {
  CONTACT_META_DESCRIPTION,
  CONTACT_META_TITLE,
} from "constants/content.constant";

const navMenu = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Flexitank",
    link: "/flexitank",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Products",
    link: "/products",
  },
];

const successMessage = (messageSuccess) => {
  message.success({
    content: messageSuccess,
    // style: { zIndex: "15000" },
    duration: 5,
    maxCount: 1,
    top: 1500,
  });
};

const warningMessage = (errorInfo) => {
  message.error({
    content: errorInfo,
    // style: { zIndex: "15000" },
    duration: 5,
    maxCount: 1,
    top: 1500,
  });
};
export default function Contact() {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(true);

  // Contact Form details
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [liquidCommodity, setLiquidCommodity] = useState("");
  const [originCityPort, setOriginCityPort] = useState("");
  const [destinationPort, setDestinationPort] = useState("");
  const [specialRequirements, setSpecialRequirements] = useState("");
  const [emailStatus, setEmailStatus] = useState("");

  const [form] = Form.useForm();

  const submitForm = (e) => {
    form.validateFields();
    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the response state and the step
      setEmailStatus(xhr.responseText);

      if (xhr.responseText === "Your response has been recorded.") {
        successMessage(xhr.responseText);
        form.resetFields();

        setName("");
        setCompanyName("");
        setEmail("");
        setWebsite("");
        setMobile("");
        setLiquidCommodity("");
        setOriginCityPort("");
        setDestinationPort("");
        setSpecialRequirements("");
      } else {
        warningMessage(xhr.responseText);
      }
    });

    // open the request with the verb and the url
    xhr.open(
      "GET",
      "https://saiflexitank.com/contact.php?name=" +
        name +
        "&company_name=" +
        companyName +
        "&website=" +
        website +
        "&email=" +
        email +
        "&mobile=" +
        mobile +
        "&liquid_commodity=" +
        liquidCommodity +
        "&origin_city_port=" +
        originCityPort +
        "&destination_port=" +
        destinationPort +
        "&special_requirements=" +
        specialRequirements
    );

    // send the request
    xhr.send();

    e.preventDefault();
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1300);
  }, []);

  const showDrawer = () => {
    setDrawer(true);
  };

  const hideDrawer = () => {
    setDrawer(false);
  };

  return (
    <div className="contact-page">
      <Helmet>
        <title>{CONTACT_META_TITLE}</title>
        <meta name="description" content={CONTACT_META_DESCRIPTION} />
        <meta property="og:title" content={CONTACT_META_TITLE} key="ogtitle" />
          <meta property="og:description" content={CONTACT_META_DESCRIPTION} key="ogdesc" />
      </Helmet>
      {loading && <Loading />}
      <Row className="contact-row">
        <Col xs={24} lg={12} xl={10}>
          <div className="contact-form">
            <header className="logo-container">
              <img
                onClick={() => showDrawer()}
                src={menu}
                alt="Flexitank"
                className="menu-icon"
              />
              <NavLink exact to="/">
                <img className="logo" src={logo} alt="Saitank" />
              </NavLink>
            </header>
            <article className="form-container">
              <h1 className="main-heading">Contact Us</h1>
              <p className="main-subheading">
                Leave it to us to find you perfect solutions for your Bulk
                Liquid Storage , Transport and Shipping
              </p>
              <div style={{ display: "flex" }}>
                <img
                  width={30}
                  style={{ marginRight: "8px" }}
                  src={phone}
                  alt="Phone"
                />
                <span style={{ fontWeight: "600", fontSize: "1.1rem" }}>
                  9819941545
                </span>
              </div>
              <Form layout="horizontal" form={form}>
                <Form.Item
                  name="name"
                  placeholder="Your Name"
                  rules={[{ required: true }]}
                  onChange={(e) => setName(e.target.value)}
                >
                  <Input placeholder="Your Name*" />
                </Form.Item>

                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="company_name"
                      placeholder="Your Company Name"
                      rules={[{ required: false }]}
                      onChange={(e) => setCompanyName(e.target.value)}
                    >
                      <Input placeholder="Your Company Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="website"
                      placeholder="Your Website"
                      rules={[{ required: false }]}
                      onChange={(e) => setWebsite(e.target.value)}
                    >
                      <Input placeholder="Your Website" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="email"
                      rules={[{ type: "email", required: true }]}
                      onChange={(e) => setEmail(e.target.value)}
                    >
                      <Input placeholder="Email*" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="phone"
                      rules={[{ required: true }]}
                      onChange={(e) => setMobile(e.target.value)}
                    >
                      <Input placeholder="Mobile*" />
                    </Form.Item>
                  </Col>
                </Row>
                <h2 className="secondary-heading">Tell us more about</h2>
                <Row gutter={16}>
                  <Col xs={24}>
                    <Form.Item
                      name="commodity"
                      onChange={(e) => setLiquidCommodity(e.target.value)}
                    >
                      <Input placeholder="Your Liquid Commodity*" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="origin"
                      onChange={(e) => setOriginCityPort(e.target.value)}
                    >
                      <Input placeholder="Your Origin City / Exit Port" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="destination"
                      onChange={(e) => setDestinationPort(e.target.value)}
                    >
                      <Input placeholder="Your Destination Port" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="comments"
                  onChange={(e) => setSpecialRequirements(e.target.value)}
                >
                  <Input placeholder="Any Special Requirements?" />
                </Form.Item>
                <Row gutter={16} className="button-row">
                  <Col xs={10}>
                    <Form.Item className="button-container">
                      <Button
                        className="contact-button"
                        type="primary"
                        htmlType="submit"
                        onClick={submitForm}
                      >
                        Submit <img src={arrow} alt="Arrow" />
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={14}>
                    <div className="mail-us">
                      <h3 className="mail-message">Kindly mail your msds to</h3>
                      <a href="mailto:bhagwan@saifreight.com">
                        bhagwan@saifreight.com
                      </a>
                    </div>
                  </Col>
                </Row>
              </Form>
            </article>
          </div>
        </Col>
        <Col xs={24} lg={12} xl={14}>
          <div className="contact-banner">
            <header className="contact-header">
              <ul className="navmenu">
                {navMenu.map((menuItem) => (
                  <li key={menuItem.link} className="navItem">
                    <NavLink exact to={menuItem.link}>
                      <p className="link">{menuItem.name}</p>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </header>
            <footer className="contact-footer"></footer>
          </div>
        </Col>
      </Row>
      <Drawer
        className="sidebar"
        title="Menu"
        placement="left"
        closable={true}
        onClose={hideDrawer}
        visible={drawer}
        style={{ zIndex: "2000" }}
        headerStyle={{ border: "none" }}
      >
        <Menu style={{ width: 256 }} mode="inline" theme="light">
          <Menu.Item key="/" onClick={() => hideDrawer()}>
            <NavLink exact to="/">
              <p className="link">Home</p>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/flexitank" onClick={() => hideDrawer()}>
            <NavLink exact to="/flexitank">
              <p className="link">Flexitank</p>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/services" onClick={() => hideDrawer()}>
            <NavLink exact to="/services">
              <p className="link">Services</p>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/products" onClick={() => hideDrawer()}>
            <NavLink exact to="/products">
              <p className="link">Products</p>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="login" onClick={() => hideDrawer()}>
            <Button className="contact-button">Enquiry</Button>
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
}
