import React from "react";
// Images
import leaves from "static/images/leaves.svg";

const BoxText = ({ text, type, large }) => {
  return (
    <div className={`color-box ${type} ${large}`}>
      <span className="box-text">
        {text !== "Benefits" && (
          <span style={{ fontSize: "1.6rem", color: "#ee1c24" }}>Zero</span>
        )}{" "}
        {text}
      </span>
    </div>
  );
};
export default function Zero() {
  return (
    <div className="zero-section">
      <div className="title-container">
        <BoxText text="Benefits" type="light" large="large" />
      </div>
      <h3 className="zero-description">
        Besides International Routes, we have an{" "}
        <b className="highlight">ALL INDIA</b> Flexitank Service to ensure 3
        main features when compared to Road Tankers and Drums.
      </h3>
      <div className="zero-container">
        <div className="zero-block">
          <BoxText text="Pilferage" type="hard" />
          <span>No Product Loss</span>
        </div>
        <div className="zero-block">
          <BoxText text="Contamination" type="light" />
          <span>Retaining Top Quality</span>
        </div>
        <div className="zero-block">
          <BoxText text="Wastage" type="hard" />
          <span>Removing Un Pumpable Material Residue</span>
        </div>
      </div>
      <div className="leaves-container">
        <img src={leaves} alt="Leaves" />
      </div>
    </div>
  );
}
