import React from "react";
import { Row, Col, Button } from "antd";
import blueArrow from "static/images/icons/blue-arrow.svg";
import whiteArrow from "static/images/icons/white-right-arrow.svg";

export default function Product({
  id,
  title,
  product_image,
  product_image2,
  description,
  direction,
  section_type,
  benefits,
  products
}) {
  return (
    <div className={`product-section ${section_type}`}>
      <Row gutter={8} className={direction}>
        <Col xs={24} md={12} className="content-col">
          <div className="title-container">
            <h2 className="product-title">{title}</h2>
          </div>
          <p className="product-description">{description}</p>
            <Row>
              <Col xs={24} xl={12}>
                <h2 style={id===2?{color:"white"}:{color:"black"}}>Benefits</h2>
                <ul className="pointers" style={{ listStyle: "none" }}>
                  {benefits.map((point) => (
                    <li
                      key={point.id}
                      style={{
                        maxWidth: "300px",
                        display: "flex",
                        alignItems: "flex-start",
                        color: id===2?"white":"black"
                      }}
                    >
                      <img
                        className="point-arrow"
                        width={16}
                        src={id === 2 ? whiteArrow : blueArrow}
                        alt="Blue Arrow"
                        style={{ marginTop: "4px", marginRight: "6px", minWidth:"16px" }}
                      />{" "}
                      <span>{point.text}</span>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col xs={24} xl={12}>
                <h2 style={id===2?{color:"white"}:{color:"black", opacity:id===3?"0":"1"}}>Products can carry:</h2>
                <ul className="pointers" style={{ listStyle: "none" }}>
                  {products && products.map((point) => (
                    <li key={point.id} style={{
                      maxWidth: "300px",
                      display: "flex",
                      alignItems: "flex-start",
                      color: id===2?"white":"black"
                    }}>
                      <img
                        className="point-arrow"
                        width={16}
                        style={{ marginTop: "4px", marginRight: "6px", minWidth:"16px" }}
                        src={id === 2 ? whiteArrow : blueArrow}
                        alt="Blue Arrow"
                      />{" "}
                      <span>{point.text}</span>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
        </Col>
        <Col xs={24} md={12}>
          <div className="image-container">
            <img src={product_image} alt={title} />
            {product_image2 &&
            <img src={product_image2} style={{marginTop:'12px'}} alt={title} />
            }

            <Button className="arrow-button"></Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
