// Images
import dryliner from "static/images/products/dry-liner.png";
import ibc from "static/images/products/ibc.png";
import flexidam from "static/images/products/flexidam.jpg";
import flexidam2 from "static/images/products/flexidam2.jpg";


import watertank1 from "static/images/products/watertank1.png";
import watertank2 from "static/images/products/watertank2.png";
import watertank3 from "static/images/products/watertank3.png";
import watertank4 from "static/images/products/watertank4.png";
import watertank5 from "static/images/products/watertank5.png";
import watertank6 from "static/images/products/watertank6.png";

export const products = [
  {
    id: 1,
    title: "Dry Liners",
    product_image: dryliner,
    description:
      // "The Sai Freight Group act as Ship Operators, Charterers, Ship Managers, Shipping Agents, Freight Forwarders, Turn-Key Logistic Service Providers, Bulk Liquid and Powder Transportation Experts besides being involved in Ship Building, Maritime Consultancy, Ship Sale & Purchase, Demolition and Highly Specialized Business Platforms.",
      "Dry Bluk Liners are used for a wide and constantly growing range of granular and powder products as a higly cost effective and time saving alternative to sacks and bags. Dry Bulk Liners form a clean, dry and protective shield between the product and the floor and walls of the container. This enables a 20', 30' and 40' bulk container to be used as a low cost alternative to a road tanker for the transport of free-flowing, bulk products. Such as dry granules, powder, grains, chemicals and other products. After discharging, the liner is simply removed and discarded, leaving the container free of residue and the danger of cross-contamination subsequent products.",
    direction: "row",
    section_type: "white-section",
    benefits: [
      {
        id: 1,
        text:
          "Prevents contamination by completely sealed bulk liners from silos of manufacturers to silos of customers.",
      },
      {
        id: 2,
        text: "Decreases contamination during unpacking.",
      },
      {
        id: 3,
        text: "Increasing loading capacity. Cuts working hours and labour costs.",
      },
      {
        id: 4,
        text: "Decreases packing costs. Decreases disposal costs of packaging.",
      },
    ],
    products: [
      {
        id: 1,
        text: "Plastic pellets/resins/granules",
      },
      {
        id: 2,
        text: "PTA and PVC powders",
      },
      {
        id: 3,
        text: "Synthetic rubber pellets",
      },
      {
        id: 4,
        text: "Carbon Black powder",
      },
      {
        id: 5,
        text: "Cement",
      },
      {
        id: 6,
        text: "Alumnia powder",
      },
      {
        id: 7,
        text: "Fish meal, Bone meal",
      },
      {
        id: 8,
        text: "Sugar, Beans, Flour, Salt, Rice",
      },
    ],
  },
  {
    id: 2,
    title: "IBCs",
    product_image: ibc,
    description:
      // "The Sai Freight Group act as Ship Operators, Charterers, Ship Managers, Shipping Agents, Freight Forwarders, Turn-Key Logistic Service Providers, Bulk Liquid and Powder Transportation Experts besides being involved in Ship Building, Maritime Consultancy, Ship Sale & Purchase, Demolition and Highly Specialized Business Platforms.",
      "IBC containers are widely used for storage and transport of liquid chemicals, cosmetics, agrochemicals, petrochemicals, parmaceuticals and food products. IBC's are relatively lower in costs when compared to metal drums. They are easy to handle and they are reusable. IBCs also saves space as they can be stacked high in their compacted form.",
    direction: "row-reverse",
    section_type: "black-section",
    benefits: [{
      id: 1,
      text:
        "Increasing Loading Capacity",
    },
    {
      id: 2,
      text: "Zero Contamination",
    },
    {
      id: 3,
      text: "Zero Demurrage Fees",
    },
    {
      id: 4,
      text: "Less Operation Handling",
    },
    {
      id: 5,
      text: "Lowcost",
    },
    {
      id: 6,
      text: "Environment Friendly",
    },
    {
      id: 7,
      text: "Safe Storage Spaces",
    },],
    products: [{
      id: 1,
      text: "Foodstuffs: Edible Oils, Vegetanle Oils, Fruit Juices, Water, Wine, Glucose, Tallow, Liquid Sugar, Food Additives, Pharmaceuticals, palm oil, sauce, vinegar, malt extract, Sorbitol, etc.",
    },
    {
      id: 2,
      text: "Industrial Oils: Transformer Oils, White Oils, Lubricants, Mineral Oils, Tung Oil, Glycerin, Base Oil, Coconut Oil, White Oils, etc.",
    },
    {
      id: 3,
      text: "Non-hazardouse liquid chemicals: Detergents, Printing Inks, Natural Latex, Synthetic Latex, Plasticizers, Concentrates, Additives, Synthetic Reins, Printing Oils, etc",
    },],
  },
  {
    id: 3,
    title: "Flexidams",
    product_image: flexidam,
    product_image2:flexidam2,
    description:
      "Flexidams are water inflated flexible structures that use water pumped inside their structures to form a flood barrier. They are made of industrial grade geomembranes and  heavy duty geotextiles. This Innovative and Versatile Dam is being used increasingly used the world over with fabulous results. The traditional view of flood protection taken by local governments is of permanent engineered  & Expensive structures such as Large Dams, Flood walls, Flood embankments and large gated barriers even at the cost of destroying the Environment & displacing People from their Natural Habitat. FlexiDams are the perfect Alternative to these permanent Structures as they are Economical & flexible enough to be applied in quick time in any terrain that requires “ Water Flow Structures & Control “ especially in locations that are not, or cannot be protected by permanent flood defenses.",
    direction: "row",
    section_type: "white-section",
    benefits: [
      {
        id: 1,
        text:
          "Environmentally Friendly -  Utilizes local water sources with almost no adverse environmental impact",
      },
      {
        id: 2,
        text: "Stable & Reliable - Inter-connected flexible tubes in twin or triangular structure. The twin and triangular configuration withstand turbidity forces against rolling or sliding.",
      },
      {
        id: 3,
        text: "Ease of Operation - Easy to transport, set up, move, remove and store.",
      },
    ],
    products: [
      {
        id: 4,
        text: "Small Footprint-  The triangular configuration provides more height for the width allowing for use in a broad range of applications.",
      },
      
      {
        id: 5,
        text: "Durable - Manufactured from high-strength Geomembrane, UV Resistant to Ultra-Violet rays and weather conditions.",
      },
      {
        id: 6,
        text: "Minimal Cleanup-  Quick and Easy cleanup after use.",
      },
    ],

  },
];

export const watertanks = [
  {
    id: 1,
    title: "WATER SUPPLY",
    alt: 'Water supply by sai flexitank',
    description: "For farming in drought areas",
    product_image: watertank1,
    color: "dark",
  },
  {
    id: 2,
    title: "WATER DISTRIBUTION",
    alt: 'Water distribution by sai flexitank',
    description: "For agriculture & rural India",
    product_image: watertank2,
    color: "light",
  },
  {
    id: 3,
    title: "WATER STORAGE",
    alt: 'Water storage by sai flexitank',
    description: "For industries & housing societies",
    product_image: watertank3,
    color: "dark",
  },
  {
    id: 4,
    title: "WATER TANKS",
    alt: 'Water tanks by sai flexitank',
    description: "For temporary construction sites",
    product_image: watertank4,
    color: "light",
  },
  {
    id: 5,
    title: "WATER APPLICATIONS",
    alt: 'Water applications by sai flexitank',
    description: "For emergency & disaster zones",
    product_image: watertank5,
    color: "dark",
  },
  {
    id: 6,
    title: "WATER CAPTURING",
    alt: 'Water capturing by sai flexitank',
    description: "For rain harvesting",
    product_image: watertank6,
    color: "light",
  },
];
