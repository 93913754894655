import React, { useState, useEffect } from "react";
// Components
import Banner from "./Banner";
import Loading from "components/Loading";
import Layout from "Layout";
import {
  DEFAULT_META_DESCRIPTION,
  DEFAULT_META_TITLE,
} from "constants/content.constant";
export default function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1300);
  }, []);
  return (
    <Layout
      metaTitle={DEFAULT_META_TITLE}
      metaDescription={DEFAULT_META_DESCRIPTION}
    >
      <div id="homepage">
        {loading && <Loading />}
        <Banner />
      </div>
    </Layout>
  );
}
