import React, { useState} from "react";
import { Toolbar, AppBar } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { Button, Drawer, Menu } from "antd";
import NavMenu from "./NavMenu";
import logo from "static/images/logo.png";
import menu from "static/images/icons/menu-icon.svg";
import whiteArrow from "static/images/icons/white-arrow.svg";
import { useLocation } from "react-router-dom";

export default function Navbar(props) {
  const [drawer, setDrawer] = useState(false);
  const { pathname } = useLocation();

  console.log(pathname);

  const showDrawer = () => {
    setDrawer(true);
  };

  const hideDrawer = () => {
    setDrawer(false);
  };
  const getColor = () => {
    switch(pathname){
      case "/services":
        return "#181D31";
      case "/flexitank":
      case "/products":
      case "/":
        return "#081998";
      default: 
      return "#081998";
    }
  }
  return (
    <React.Fragment>
      <AppBar style={{backgroundColor: getColor()}} position="fixed" className="transparentStyle" id="navbar">
        <Toolbar className="toolbar">
          <img
            onClick={() => showDrawer()}
            src={menu}
            alt="Flexitank"
            className="menu-icon"
          />
          <NavLink exact to="/">
            <img style={pathname === "/" ? {width:"170px"}:{}} className="logo" src={logo} alt="Sai Flexitank" />
          </NavLink>
          <div className="grow" />
          <NavMenu />
        </Toolbar>
      </AppBar>

      <Drawer
        className="sidebar"
        title="Menu"
        placement="left"
        closable={true}
        onClose={hideDrawer}
        visible={drawer}
        style={{ zIndex: "2000" }}
        headerStyle={{ border: "none" }}
      >
        <Menu style={{ width: 256 }} mode="inline" theme="light">
          <Menu.Item key="/" onClick={() => hideDrawer()}>
            <NavLink exact to="/">
              <p className="link">Home</p>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/flexitank" onClick={() => hideDrawer()}>
            <NavLink exact to="/flexitank">
              <p className="link">Flexitank</p>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/services" onClick={() => hideDrawer()}>
            <NavLink exact to="/services">
              <p className="link">Services</p>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/products" onClick={() => hideDrawer()}>
            <NavLink exact to="/products">
              <p className="link">Products</p>
              <img className="arrow" src={whiteArrow} alt="White Arrow" />
            </NavLink>
          </Menu.Item>
          <Menu.Item key="login" onClick={() => hideDrawer()}>
            <Button className="contact-button">Contact us</Button>
          </Menu.Item>
        </Menu>
      </Drawer>
    </React.Fragment>
  );
}
