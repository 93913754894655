import React from "react";
import { Row, Col } from "antd";
import { watertanks } from "lists";

const WaterTank = ({ title, alt, product_image, color, description }) => {
  return (
    <div className="watertank">
      <div className="image-container">
        <img className="watertank-image" src={product_image} alt={alt} />
      </div>
      <div
        className={`title-container ${
          color === "light" ? "light-title" : "dark-title"
        }`}
      >
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
export default function Watertank() {
  return (
    <div className="watertank">
      <h2 className="main-heading">Flexitanks for water</h2>
      <p className="main-description">
      Our Sai Water Flexitanks are highly reliable and economical for both, commercial and domestic purposes.
      </p>
      <div className="watertanks-container">
        <Row gutter={16}>
          {watertanks.map((watertank) => (
            <Col key={watertank.id} xs={24} lg={12}>
              <WaterTank {...watertank} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}
