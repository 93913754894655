import React from "react";
import BoxText from "components/BoxText";
import blueArrow from "static/images/icons/blue-arrow.svg";
import whiteArrow from "static/images/icons/white-right-arrow.svg";

export default function Service({ type, side, description, heading, points, hash }) {
  return (
    <div className={`service-box ${side} ${type}`}>
      <div id={hash} className="hash" />
      <BoxText text={heading} type={type === "hard" ? "light" : "hard"} />
      {description}
      {points && (
        <ul>
          {points.map((point, index) => (
            <li key={index}>
              <img src={type === "hard" ? whiteArrow : blueArrow} alt="arrow" />
              {point}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
