import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "antd";

// Images
import blackArrow from "static/images/icons/black-arrow.svg";


const navMenu = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "Flexitank",
        link: "/flexitank"
    },
    {
        name: "Services",
        link: "/services"
    },
    {
        name: "Products",
        link: "/products"
    },
]
export default function NavMenu() {
  return (
    <React.Fragment>
      <ul className="navmenu">
        {navMenu.map((menuItem)=>(
            <li key={menuItem.link} className="navItem">
            <NavLink exact to={menuItem.link}>
              <p className="link">{menuItem.name}</p>
            </NavLink>
          </li> 
        ))
        }
        <li className="navItem">
          <NavLink exact to="/enquiry">
            <Button className="white-button">Contact us <img src={blackArrow} alt="Black Arrow" /></Button>
          </NavLink>
        </li>
      </ul>
    </React.Fragment>
  );
}
