import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
// Components
import Layout from "Layout";
import Banner from "./Banner";
import Service from "./Service";
import Loading from "components/Loading";

import { serviceContent } from "lists";
import {
  SERVICES_META_DESCRIPTION,
  SERVICES_META_TITLE,
} from "constants/content.constant";
export default function Services() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1300);
  }, []);
  return (
    <Layout
      metaTitle={SERVICES_META_TITLE}
      metaDescription={SERVICES_META_DESCRIPTION}
    >
      {loading && <Loading />}
      <div id="services">
        <Banner />
        <Row>
          {serviceContent.map((content) => (
            <Col xs={24} md={12} key={content.id}>
              <Service {...content} />
            </Col>
          ))}
        </Row>

        {/* <Zero /> */}
      </div>
    </Layout>
  );
}
